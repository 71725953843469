import { useContext } from "react";
import withDefaultQueuePoller from "../components/hoc/withDefaultQueuePoller";
import withMAQueuePoller from "../components/hoc/withMAQueuePoller";
import withNextUpQueuePoller from "../components/hoc/withNextUpQueuePoller";
import withPresenceHeartbeat from "../components/hoc/withPresenceHeartbeat";
import { FeatureToggleContext } from "../context/FeatureToggleContext";
import { isFeatureEnabled } from "../functions/consult/isFeatureEnabled";
import { useSession } from "../functions/useSession";
import { FeatureNameEnum } from "../hooks/useFeatureToggle";
import { Clinician } from "../types/consult-api";
import { DefaultQueue } from "./defaultQueue";
import MAQueue from "./maQueue";
import NextUpQueue from "./nextUpQueue";

function Index() {
    const { features } = useContext(FeatureToggleContext);
    const [session] = useSession();
    const sessionUser = session?.user as any;
    const clinician = sessionUser?.clinician as Clinician;

    if (isFeatureEnabled(features, FeatureNameEnum.NextConsultModal)) {
        const NextUpQueueWithPoller = withNextUpQueuePoller(NextUpQueue);
        return <NextUpQueueWithPoller clinician={clinician} />;
    }
    if (clinician && clinician.isMa) {
        const MAQueueWithPoller = withMAQueuePoller(MAQueue);
        return <MAQueueWithPoller clinician={clinician} />;
    } else {
        const DefaultQueueWithPoller = withDefaultQueuePoller(DefaultQueue);
        return <DefaultQueueWithPoller clinician={clinician} />;
    }
}

export default withPresenceHeartbeat(Index);
